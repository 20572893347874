<template>
    <div>
      <div id="loading" v-if="loading" style="display: flex; color: #FFF; align-items: center; justify-content: center; height: 100vh;">
        <div>
            <div id="loader">
                <Vue3Lottie :animationData="galaxyJson" :height="250" :width="250" />
            </div>
        </div>
      </div>

      <div v-else>
        <span class="switch">
          <label class="switch__wrapper">
            <input
              ref="languageCheckbox"
              type="checkbox"
              :checked="language"
              @click="switchLanguage"
            />
            <span class="switch__slider"></span>
            <div></div>
          </label>
        </span>
        <navbar />
        <router-view />
        <SpeedInsights />
        <primary-footer />
      </div>
    </div>
  </template>

<script>
import { Vue3Lottie } from 'vue3-lottie';
import PrimaryFooter from "@/components/PrimaryFooter.vue";
import Navbar from "./components/Navbar.vue";
import galaxyJson from './assets/animations/galaxy-loader.json';
import { SpeedInsights } from "@vercel/speed-insights/vue";

  export default {
    components: {
      PrimaryFooter,
      Navbar,
      Vue3Lottie,
    },

    data() {
      return {
        loading: true,
        galaxyJson
      };
    },

    mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },

    computed: {
      language() {
        return this.$store.state.language;
      },
    },

    methods: {
      switchLanguage() {
        this.$store.commit('setLanguage', !this.language);
      },
    },
  };
</script>


<style lang="scss" scoped>

.switch {
    position: absolute;
    top: 0;
    margin-top: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
}
.switch__wrapper {
  position: relative;
  display: inline-block;
  width: 75px;
  height: 24px;
  background: #d4d4d4;
  border: 1px solid #d4d4d4;
  box-sizing: border-box;
  border-radius: 28px;
  margin: 0px 1em 0px 0px;
}
.switch__wrapper input {
  display: none;
}
.switch__wrapper input:checked + .switch__slider:before {
  transform: translateX(32px);
}
.switch__wrapper input:checked ~ div:after {
  color: #ffffff;
  transition: color 0.3s ease 0s;
}
.switch__wrapper input:checked ~ div:before {
  color:  rgb(3, 0, 20);
  transition: color 0.3s ease 0s;
}
.switch div::before {
  content: "US";
  position: relative;
  z-index: 5;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
  bottom: 0;
  left: 10px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.switch div::after {
  content: "BR";
  position: relative;
  z-index: 5;
  font-weight: 700;
  font-size: 12px;
  color: rgb(3, 0, 20);
  bottom: 0;
  left: 30px;
  cursor: pointer;
  transition: color 0.3s ease 0s;
}
.switch__slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  border-radius: 34px;
}
.switch__slider::before {
  position: absolute;
  content: "";
  width: 47px;
  height: 27px;
  left: -3px;
  bottom: -3px;
  background-color: rgb(3, 0, 20);
  transition: all 0.4s ease 0s;
  border-radius: 28px;
  border: 1px solid #a48fff1f;
}

#loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 750px) {
    .switch {
        z-index: 2;
    }
}


@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
</style>
