<template>
  <div class="about-container">
    <video
      id="background-video"
      autoplay
      loop
      muted
      poster="../../public/videos/blackhole.webm"
    >
      <source src="../../public/videos/blackhole.webm" type="video/webm" />
    </video>
    <div class="about-content">
      <div>
        <p v-html="aboutText1"></p>
        <br />
        <p v-html="aboutText2"></p>
        <br />
        <p v-html="aboutText3"></p>
        <br />
        <p v-html="aboutText4"></p>
        <br />
      </div>
      <div class="container-circles">
        <div class="str-circle1"></div>
        <div class="str-circle2"></div>
        <div class="str-circle3"></div>
        <span v-html="funFactText"></span>
      </div>
    </div>
  </div>
</template>

  <script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["language"]),
    aboutText1() {
      return this.language ? "Oi, eu sou Hosana." : "Hi, I am Hosana.";
    },
    aboutText2() {
      return this.language
        ? "Bacharelada em Sistemas de Informação pela Universidade Unigranrio, com experiência em desenvolvimento de software e metodologias. Atualmente trabalho como Team Leader e sou desenvolvedora de sistemas. Com anos de foco e experiência, sigo criando projetos únicos e priorizando entregas de qualidade."
        : "Graduated in the Bachelor of Information Systems course at Unigranrio University, with experience in software development and methodologies. I currently work as a Team Leader and I am a developer of systems. With years of focus and experience, I continue to create unique projects and prioritize quality deliveries.";
    },
    aboutText3() {
      return this.language
        ? 'Você pode encontrar artigos diversos que escrevo no meu <a href="https://medium.com/@hosanabarcelos" target="_blank">Medium</a> se é interessado em leitura. E, se você estiver interessado em ver meus projetos, poderá vê-los no meu <a href="https://github.com/hosanabarcelos" target="_blank">GitHub</a>.'
        : "You can find miscellaneous articles I write on my <a href='https://medium.com/@hosanabarcelos' target='_blank'>Medium</a> if you are interested in reading. And, if you're interested in seeing my projects, you can see them on my <a href='https://github.com/hosanabarcelos' target='_blank'>GitHub</a>.";
    },
    aboutText4() {
      return this.language
        ? "Fun fact: Sou viciada em música e amante de café. Me interesso por assuntos psíquicos, astronomia e escrevo coisas."
        : "Fun fact: I'm addicted to music and coffee lover. I am interested in psychic subjects, astronomy and I write things.";
    },
    funFactText() {
      return this.language
        ? 'Criando bugs e ouvindo <a href="https://lofizinho.vercel.app/" target="_blank">Lofi</a>.'
        : 'Creating bugs and listening to <a href="https://lofizinho.vercel.app/" target="_blank">Lofi</a>.';
    },
  },
};
</script>

<style lang="scss" scoped>
#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  bottom: -400px;
  z-index: -1;
  opacity: 0.2;
}

.container-circles {
  display: flex;
  align-items: center;
  height: 1vh;
  width: 100%;
  margin-top: 2rem;

  span {
    color: #f6f6f6;
    font-size: 0.9rem;
    margin-left: 20px;
  }
}

.str-circle1,
.str-circle2,
.str-circle3 {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: radial-pulse 4s infinite;
}
.str-circle1 {
  background: rgb(154, 98, 218);
}
.str-circle2 {
  animation-delay: 1.3333s;
}
.str-circle3 {
  animation-delay: 2.6666s;
}

.about-content {
  width: 40%;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 32px;

  p {
    color: #f6f6f6;
  }
}
.about-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0em rgb(155, 99, 175);
  }
  100% {
    box-shadow: 0 0 0 0.5rem rgba(255, 100, 100, 0);
  }
}

@media screen and (max-width: 740px) {
  .about-content {
    width: 95%;
    margin-top: 75px;
    font-size: 0.9rem;
  }

  .about-container {
    justify-content: flex-start !important;
  }
}
</style>
