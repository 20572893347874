import { createRouter, createWebHistory } from 'vue-router';
import Main from '../views/Main.vue';
import AboutPageVue from '@/views/AboutPage.vue';
import ContactPageVue from '@/views/ContactPage.vue';
import InitPageVue from '@/views/InitPage.vue';
import RecomendationsVue from '@/views/Recomendations.vue';
import ExperiencePageVue from '@/views/ExperiencePage.vue';

const routes = [
	{
		path: '/',
		name: 'main',
		component: InitPageVue
	},
    {
		path: '/about',
		name: 'about',
		component: AboutPageVue
	},
    {
		path: '/contact',
		name: 'contact',
		component: ContactPageVue
	},
    {
		path: '/feedbacks',
		name: 'recomendatons',
		component: RecomendationsVue
	},
    {
		path: '/experience',
		name: 'experience',
		component: ExperiencePageVue
	},
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
